import React from 'react';

import {Box} from '@mui/material';

const Image = (props) => {
  return (
    <Box
      component="img"
      {...props}
    />
  );
};

export default Image;
