export default {
  Meta: {
    Title: 'Slack | Ticketing | IT Support | Customer Service',
    Description: `
      Give your team a world-class ticketing and issue tracking system to help you deal with issues and incidents in your business.
      From the moment the incident is raised to its resolution, our ticketing system will be there to pick up the grunt work 
      so you can focus on the issue and be engaged with the customer.
    `,
  },
  Introduction: {
    Title: 'Netflare Ticketing',
    Body: 'Gain a light-weight ticketing system that\'s fully connected to our Netflare Ticketing platform.',
  },
  Button: 'Add to Slack',
  Summary: `
    Easily access <strong>Netflare</strong> tickets directly from <strong>Slack</strong>. Create and manage tickets even when you
    are on the go.
  `,
  ViewingTickets: {
    Title: 'Viewing and filtering tickets',
    Body: 'Tired of losing track of tickets? Filter and find your tickets in Slack so you can easily update your team on your progress.',
  },
  CreatingTickets: {
    Title: 'Creating tickets',
    Body: `
      Create tickets on the homepage or create a ticket from anywhere with <strong>/create</strong>.
      All tickets created are synchronized with Netflare\'s plateform.
    `,
  },
  BringTicketsToSlack: {
    Title: 'Bring tickets to Slack',
    Body: 'By default, bringing a ticket into Slack will open it\'s own channel or you can choose to bring all tickets into one triage channel.',
  },
  Triage: {
    Title: 'Setup a triage channel',
    Body: `
      Head over to the main page to setup a triage channel. Select a Slack channel and
      choose whether you would like tickets to automatically be posted as they are created.
    `,
  },
  Setup: {
    Title1: 'How to setup',
    Title2: 'Netflare Ticketing',
    1: {
      Title: 'Step 1',
      Body: 'Install Netflare Ticketing by clicking the Add to Slack button.',
    },
    2: {
      Title: 'Step 2',
      Body: 'Connect Netflare to Slack by pressing the Allow button.',
    },
    3: {
      Title: 'Step 3',
      Body: 'You\'re set! Visit the app homepage to start creating tickets.',
    },
  },
  Footer: {
    Message: 'Try Netflare Ticketing for Slack',
    Link: 'Add to Slack',
  },
  SlackIntegration: 'Integration for Slack',
  LearnMore: 'Learn more about the integration for Slack →',
};
