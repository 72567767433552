export default {
  Meta: {
    Title: 'Pricing',
    Description: `
      Here at Netflare, we strive to keep your customers happy by ensuring your digital presence is stable,
      secure and efficient. When things go wrong (and they do), Netflare allows you to create a workflow to immediately
      raise the alarm, automatically mobilize support, and execute on a fix.
    `,
  },
  Title: 'Pricing',
  CostPerUser: 'per user',
  CostPerTime: '/month',
  OurPick: 'Our Pick',
  GetStarted: 'Get started',
  PlanDetail: 'Our Plans Details',
  Fields: {
    General: {
      MaxUser: {
        Title: 'Max users',
      },
      Teams: {
        Title: 'Teams',
      },
      DataRetention: {
        Title: 'Data Retention',
      },
      SSO: {
        Title: 'SSO',
      },
    },

    Products: {
      Ticketing: 'Ticketing',
      Pager: 'Pager',
      Runbook: 'Runbook',
      Pulse: 'Pulse',
      Alarm: 'Alarm',
    },

    ProductsDetail: {
      Ticketing: {
        IssueTracking: {
          Title: 'Issue tracking',
        },
        Triaging: {
          Title: 'Triaging',
        },
        Collaboration: {
          Title: 'Collaboration',
        },
        Subscription: {
          Title: 'Ticket subscription',
        },
        CustomTags: {
          Title: 'Custom tags',
        },
        PagerWorkflows: {
          Title: 'Pager workflow',
        },
      },

      Pager: {
        SlackIntegration: {
          Title: 'Slack notification',
        },
        EmailNApp: {
          Title: 'Email & App notification',
        },
        SMS: {
          Title: 'SMS notification',
        },
        PhoneCall: {
          Title: 'Voice call notification',
        },
        PersonalEscalation: {
          Title: 'Personal Escalation',
        },
        OncallSchedule: {
          Title: 'Oncall schedules',
        },
        FutureScheduling: {
          Title: 'Future scheduling',
          Info: 'Determines how far ahead in the future you can schedule your oncall shifts',
        },
        DispatchingPolicy: {
          Title: 'Dispatching policys',
        },
      },

      Runbook: {
        OpenDraft: {
          Title: 'Number of open drafts',
        },
        SizeOfLibrary: {
          Title: 'Max size of library',
        },
        StorageRetenetion: {
          Title: 'Storage retention',
        },
      },

      Pulse: {
        NumOfPulses: {
          Title: 'Number of active Pulses',
        },
        StorageRetenetion: {
          Title: 'Storage retention',
        },
        Regions: {
          Title: 'Regions',
        },
        AdvancedSettings: {
          Title: 'Advanced Settings',
        },
      },

      Alarm: {
        AlarmMonitoring: {
          Title: 'Alarm monitoring',
        },
        StorageRetenetion: {
          Title: 'Storage retention',
        },
        ActionsPerAlarm: {
          Title: 'Automated actions',
        },
        Aws: {
          Title: 'AWS',
        },
        Azure: {
          Title: 'Azure',
        },
        Grafana: {
          Title: 'Grafana',
        },
        Datadog: {
          Title: 'Datadog',
        },
        Api: {
          Title: 'API',
        },
        Manual: {
          Title: 'Manual',
        },
      },
    },
  },
  PriceModels: {
    Basic: {
      Info: {
        Rank: 1,
        Category: 'Basic',
        Price: 'Free',
        Explanation: 'Get started with our always free plan',
      },

      General: {
        MaxUser: '100 max users',
        Teams: '1 team',
        DataRetention: '3 months data retention',
        SSO: 'Extra cost SSO',
      },

      Products: {
        Ticketing: {
          IssueTracking: true,
          Triaging: true,
          Collaboration: true,
          Subscription: true,
          CustomTags: null,
          PagerWorkflows: '1 per team',
        },

        Pager: {
          SlackIntegration: true,
          EmailNApp: true,
          SMS: null,
          PhoneCall: null,
          PersonalEscalation: true,
          OncallSchedule: 2,
          FutureScheduling: '2 months ahead',
          DispatchingPolicy: 0,
        },

        Runbook: {
          OpenDraft: 5,
          SizeOfLibrary: 2,
          StorageRetenetion: '3 months',
        },

        Pulse: {
          NumOfPulses: 2,
          StorageRetenetion: '3 months',
          Regions: 'US West',
          AdvancedSettings: 'Minimal',
        },

        Alarm: {
          AlarmMonitoring: 2,
          StorageRetenetion: '3 months',
          ActionsPerAlarm: '1 per alarm',
          Aws: true,
          Azure: true,
          Grafana: true,
          Datadog: true,
          Api: true,
          Manual: true,
        },
      },
    },
    Professional: {
      Info: {
        Rank: 2,
        Category: 'Professional',
        Price: 14,
        Explanation: `Incident response plan for your small team`,
      },

      General: {
        MaxUser: 'Unlimited users',
        Teams: '5 teams',
        DataRetention: '12 months data retention',
        SSO: 'Extra cost SSO',
      },

      Products: {
        Ticketing: {
          IssueTracking: true,
          Triaging: true,
          Collaboration: true,
          Subscription: true,
          CustomTags: '5 per team',
          PagerWorkflows: '5 per team',
        },

        Pager: {
          SlackIntegration: true,
          EmailNApp: true,
          SMS: false,
          PhoneCall: false,
          PersonalEscalation: true,
          OncallSchedule: 5,
          FutureScheduling: '5 months ahead',
          DispatchingPolicy: 5,
        },

        Runbook: {
          OpenDraft: 20,
          SizeOfLibrary: 20,
          StorageRetenetion: '12 months',
        },

        Pulse: {
          NumOfPulses: 5,
          StorageRetenetion: '12 months',
          Regions: 'All regions',
          AdvancedSettings: 'Advanced',
        },

        Alarm: {
          AlarmMonitoring: 5,
          StorageRetenetion: '12 months',
          ActionsPerAlarm: '2 per alarm',
          Aws: true,
          Azure: true,
          Grafana: true,
          Datadog: true,
          Api: true,
          Manual: true,
        },
      },
    },
    Business: {
      Info: {
        Rank: 3,
        Category: 'Business',
        Price: 29,
        Explanation: 'Secure your digital presence at scale',
        OurPick: true,
      },

      General: {
        MaxUser: 'Unlimited users',
        Teams: 'Unlimited teams',
        DataRetention: '5 years data retention',
        SSO: 'SSO Included',
      },

      Products: {
        Ticketing: {
          IssueTracking: true,
          Triaging: true,
          Collaboration: true,
          Subscription: true,
          CustomTags: '20 per team',
          PagerWorkflows: '20 per team',
        },

        Pager: {
          SlackIntegration: true,
          EmailNApp: true,
          SMS: true,
          PhoneCall: true,
          PersonalEscalation: true,
          OncallSchedule: 20,
          FutureScheduling: '24 months ahead',
          DispatchingPolicy: 20,
        },

        Runbook: {
          OpenDraft: 200,
          SizeOfLibrary: 200,
          StorageRetenetion: '5 years',
        },

        Pulse: {
          NumOfPulses: 200,
          StorageRetenetion: '5 years',
          Regions: 'All regions',
          AdvancedSettings: 'Advanced',
        },

        Alarm: {
          AlarmMonitoring: 200,
          StorageRetenetion: '5 years',
          ActionsPerAlarm: '5 per alarm',
          Aws: true,
          Azure: true,
          Grafana: true,
          Datadog: true,
          Api: true,
          Manual: true,
        },
      },
    },
    Enterprise: {
      Info: {
        Rank: 4,
        Category: 'Enterprise',
        Price: 'Contact us',
        Explanation: `For advanced setup and more flexible control`,
      },

      General: {
        MaxUser: 'Unlimited users',
        Teams: 'Unlimited teams',
        DataRetention: 'Unlimited data retention',
        SSO: 'SSO Included',
      },

      Products: {
        Ticketing: {
          IssueTracking: true,
          Triaging: true,
          Collaboration: true,
          Subscription: true,
          CustomTags: 'Unlimited',
          PagerWorkflows: 'Unlimited',
        },

        Pager: {
          SlackIntegration: true,
          EmailNApp: true,
          SMS: true,
          PhoneCall: true,
          PersonalEscalation: true,
          OncallSchedule: 'Unlimited',
          FutureScheduling: 'Unlimited',
          DispatchingPolicy: 'Unlimited',
        },

        Runbook: {
          OpenDraft: 'Unlimited',
          SizeOfLibrary: 'Unlimited',
          StorageRetenetion: 'Unlimited',
        },

        Pulse: {
          NumOfPulses: 'Unlimited',
          StorageRetenetion: 'Unlimited',
          Regions: 'All regions',
          AdvancedSettings: 'Advanced',
        },

        Alarm: {
          AlarmMonitoring: 'Unlimited',
          StorageRetenetion: 'Unlimited',
          ActionsPerAlarm: 'Unlimited',
          Aws: true,
          Azure: true,
          Grafana: true,
          Datadog: true,
          Api: true,
          Manual: true,
        },
      },
    },
  },
};
