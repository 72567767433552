export default {
  Meta: {
    Title: 'Alarm | Monitoring  | Performance',
    Description: `
      Set up alarms for when things don’t go as planned, and sit back as Netflare does the hard work of monitoring your customer experience,
      giving you the peace of mind to focus on growing your business. Netflare Alarms will let your IT Support team know
      as soon as it sees unexpected changes in business and system health KPIs, so that you can get eyes on the problem and minimize
      the time to resolution.
    `,
  },
  Body: `
    Setup automated alarms and sit back as Netflare does the hard work of <span style="color:#2746AC">monitoring</span> your customer experience
  `,
  SubBody: `
    Netflare Alarming will let your IT Support team know 
    as soon as it sees <span style="color:#FC8309">unexpected changes</span> in business and system health KPIs,
    so that you can get eyes on the problem and <span style="color:#FC8309">minimize time to resolution</span>.
  `,
  Resolution: {
    Title: 'Minimize time to resolution',
    1: {
      Title: 'Automatic alarming',
      Body: `
        Netflare will raise the alarm as soon as it detects a problem. Setup automated workflows in Netflare Pager and Ticketing,
        and we will get the right eyes on problems as soon as they occur.
      `,
    },
    2: {
      Title: 'Automated tracking',
      Body: `
        Keep track of the health of your business at all times not only to know when there is a problem but 
        also when a fix has brought your business back to a healthy state.
      `,
    },
    3: {
      Title: 'Get visibility and insight',
      Body: `
        Keep track of all your system health metrics with a quick glance and easily follow up on previous incidents.
      `,
    },
  },
  Integrate: {
    Title: 'Integrates with the Cloud',
    1: {
      Title: 'Ready to integrate',
      Body: `
        Netflare  supports several integration methods so that you can have the pulse on all your core services. 
        This lets us give you insights and alerts as soon as the situation changes, reducing downtime and the effort required to monitor.
        We currently offer integrations via the following:

        
        <ul>
          <li>
            Amazon Web Services (AWS)
          </li>
          <li>
            Microsoft Azure
          </li>
          <li>
            Grafana
          </li>
          <li>
            Datadog
          </li>
          <li>
            Zendesk
          </li>
          <li>
            Direct API integration
          </li>
        </ul>
      `,
    },
  },
  Action: {
    Previous: 'PULSE',
    Next: 'TICKETING',
  },
};
