export default {
  Meta: {
    Title: 'Pulse | Web Performance | Web Health',
    Description: `
      Pulse is a web monitoring tool that pings your web pages or API endpoints and checks the output and response time.
      It alerts you of any issues and helps you fix them quickly. Try it for free today!
    `,
  },
  Body: `
    The heartbeat of your web presence
  `,
  SubBody: `
  Pulse monitors your website and API <span style="color:#FC8301">uptime and response time</span>, 
  so you can fix issues before they affect your <span style="color:#FC8301">customers</span>.
  `,
  Pulse: {
    Title: 'Pulse',
    1: {
      Title: 'Keep your website and API up and running',
      Body: `
        Pulse is a web monitoring tool that ensures your web host is responsive and functioning as expected. 
        It periodically pings your web pages or API endpoints and checks the output and response time for any issues.
      `,
    },
    2: {
      Title: 'Easy to onboard',
      Body: `Pulse is simple to set up and get started. All you need to do is provide us with the endpoint, and we take care of the rest.`,
    },
    3: {
      Title: 'Get notified and fix problems quickly',
      Body: `
        When Pulse detects a problem with your website or API, it alerts you through Netflare Pager and helps you document the outage with Netflare Ticket.
        With Pulse, you can resolve issues faster and minimize downtime.
      `,
    },
  },
  Monitoring: {
    Title: 'Monitoring',
    1: {
      Title: 'Ensure content integrity with content checking',
      Body: `
        Pulse performs deep output checks on your web pages or API endpoints to verify that the content is as expected. 
        It can detect any changes or errors in the content and alert you accordingly.
      `,
    },
    2: {
      Title: 'Measure performance and detect slowdowns',
      Body: `
        Pulse monitors the response time of your web pages or API endpoints to assess how fast they load and respond.
        It can detect any slowdowns or timeouts that may affect your user experience and keep you informed.
      `,
    },
    3: {
      Title: 'Stay close to your customer base with regional pinging',
      Body: `
        Pulse allows you to choose the region from which to ping your web pages or API endpoints.
        This way, you can better represent your customer base and ensure that your website or API is accessible and responsive in different locations.
      `,
    },
  },
  Alerting: {
    Title: 'Failure detection',
    1: {
      Title: 'Avoid false-positive alerts with double-checking failures',
      Body: `
        Pulse double-checks any failures before sending you an alert.
        This way, you can avoid false-positive alerts that waste your time and resources.
      `,
    },
    2: {
      Title: 'Customize your check frequency to suit your needs',
      Body: `
        Pulse lets you customize how often you want to check your web pages or API endpoints.
        You can choose from one minute to every day, depending on your preferences and requirements.
      `,
    },
    3: {
      Title: 'Capture snapshot of your web pages for historical reference',
      Body: `
        Pulse captures screenshots of your web pages every time it pings them. 
        These screenshots let you see what went wrong and when, so you can easily debug the issue. 
      `,
    },
  },
  Action: {
    Previous: 'RUNBOOK',
    Next: 'ALARM',
  },
};
