import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress as CircularProgressMui, LinearProgress} from '@mui/material';

import {Box, Typography} from '../';

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 10}}>
      <Box sx={{width: '90%', mr: 1}}>
        <LinearProgress variant='determinate' color='primary' {...props} />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2" color='primary'>{`${Math.round(
            props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  errors: PropTypes.array,
};

const LinearProgressDeterminate = (props) => {
  return (
    <Box sx={{width: '90%', mr: 1}}>
      <LinearProgress variant='determinate' color='primary' {...props} />
    </Box>
  );
};
LinearProgressDeterminate.propTypes = {
};

const CircularProgress = (props) => {
  return (
    <CircularProgressMui color={'secondary'} {...props} />
  );
};
CircularProgress.propTypes = {
};


export {
  CircularProgress, LinearProgress, LinearProgressWithLabel, LinearProgressDeterminate,
};
