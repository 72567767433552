import {createTheme} from '@mui/material/styles';

import {inputLabelClasses} from '@mui/material/InputLabel';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#2746AC',
      light: '#E8EDFC',
      dark: '#223880',
    },
    secondary: {
      main: '#FC8301',
      light: '#FCF2E8',
      dark: '#804200',
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
    },
    warning: {
      main: '#FFB400',
      light: '#ff9800',
      dark: '#e65100',
    },
    info: {
      main: '#2196F3',
      light: '#64B5F6',
      dark: '#1976D2',
    },
    success: {
      main: '#4CAF50',
      light: '#81C784',
      dark: '#388E3C',
    },
    grey: {
      900: '#1C1C1F',
      800: '#2F3033',
      700: '#6D6E73',
      600: '#84858A',
      500: '#B3B4BA',
      400: '#D0D1D6',
      300: '#E4E5EB',
      200: '#F0F2F7',
      100: '#FAFBFC',
    },
    color: {
      white: '#FFFFFF',
      black: '#000000',
      lightGrey: '#D4D4D4',
      darkGrey: '#565656',
      gold: '#ECB22E',
    },
    background: {
      main: '#F6F8FF',
      highlighted: '#F5F5F5',
      logo: '#F3F3F3',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 2200,
    },
  },
  components: {
    'MuiLink': {
      styleOverrides: {
        root: {
          'color': '#06c',
        },
      },
    },
    'MuiInputLabel': {
      styleOverrides: {
        root: {
          fontSize: '1.25rem',
          [`&.${inputLabelClasses.formControl}`]: {
            color: '#B3B4BA',
          },
          [`&.${inputLabelClasses.shrink}`]: {
            color: '#6D6E73',
          },
        },
      },
    },
  },
});

const DEFAULT_FONT = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '140%',
};

theme.typography = {
  pxToRem: (size) => `${(size / 16)}rem`,
  ...DEFAULT_FONT,
  h1: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '3rem',
    lineHeight: '100%',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  h2: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.0rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.875rem',
    },
  },
  h3: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '2.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    },
  },
  h4: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '2rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.5rem',
    },
  },
  h5: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem',
    },
  },
  h6: {
    ...DEFAULT_FONT,
    fontWeight: '700',
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.0rem',
    },
  },
  body1: {
    ...DEFAULT_FONT,
    fontWeight: '400',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  body2: {
    ...DEFAULT_FONT,
    fontWeight: '400',
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
    },
  },
  caption: {
    ...DEFAULT_FONT,
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '100%',
  },
  footer: {
    ...DEFAULT_FONT,
    fontWeight: '400',
    fontSize: '0.625rem',
    lineHeight: '150%',
  },
  logo1: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '2rem',
    color: '#000000',
  },
  logo2: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1.5rem',
    color: '#000000',
  },
  logo3: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '1rem',
    color: '#000000',
  },
  logo4: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '1.15rem',
    color: '#000000',
  },
};
