export default {
  Meta: {
    Title: 'Runbook | Instruction | Knowledge | Issue',
    Description: `
      Leverage the knowledge of your subject matter experts for the benefit of your whole team.
      With our runbook, you can share knowledge across your team and execute complex operations  through easy to follow, step-by-step instructions.
      Build a library of your knowledge base and use it to tackle all your operations without hesitation.
    `,
  },
  Body: `
    Leverage the <span style="color:#2746AC">knowledge</span> of your subject matter experts for your whole team
  `,
  SubBody: `
    With our runbook, you can  <span style="color:#FC8309">share knowledge</span> in your team and <span style="color:#FC8309">execute</span>
    on them through easy to follow step by step instruction.
    Build a <span style="color:#FC8309">library</span> of your knowledge base and use it tacke all your issues without hesitation 24/7.
  `,
  Document: {
    Title: 'Document your process',
    1: {
      Title: 'Share knowledge',
      Body: `Capture your expertise and easily share it with your team.`,
    },
    2: {
      Title: 'Build a library',
      Body: `Accumulate your knowledge over time in a centralized library that is always at your fingertips.`,
    },
    3: {
      Title: 'Manage legacy process',
      Body: `Reduce the consequences of losing key technical experts by documenting your processes.`,
    },
    4: {
      Title: 'Keep it consistent',
      Body: `Provide your customers with consistent experience through consistent procedures.`,
    },
  },
  Solve: {
    Title: 'Solve problems efficiently',
    1: {
      Title: 'Step-by-step guide',
      Body: `Execute your runbooks with step-by-step instructions so you get it done right.`,
    },
    2: {
      Title: 'Easily identify the right solution',
      Body: `Search through your library for the right solution to your problem.`,
    },
    3: {
      Title: 'Define rollback procedure',
      Body: `Plan for the worst scenarios and let us help you execute it.`,
    },
    4: {
      Title: 'Follow up on progress',
      Body: `Give stakeholders the tools to stay informed and keep track of progress.`,
    },
  },
  Action: {
    Previous: 'PAGER',
    Next: 'PULSE',
  },
};
