export default {
  Meta: {
    Title: 'Operational Excellence | Pager | Ticketing',
    Description: `
      Here at Netflare, we strive to keep your customers happy by ensuring your digital presence is stable,
      secure and efficient. When things go wrong (and they do), Netflare allows you to create a workflow to immediately
      raise the alarm, automatically mobilize support, and execute on a fix.
    `,
  },
  Motivation: {
    FirstSlide: 'Everything you’ll need to keep your software infrastructure running',
    SecondSlide: 'Don’t solve each problem with a new SaaS subscription. Do more with less',
    ThirdSlide: 'Let’s get started',
  },
  Title: 'Your digital presence <span style="color:#2746AC">always online</span>',
  Body: `
    Here at Netflare, we strive to keep your customers happy by ensuring your digital presence is stable, secure and efficient. 
    For when things go wrong (and they do) Netflare allows you to create a workflow to immediately <span style="color:#FC8309">raise the alarm</span>,
    automatically <span style="color:#FC8309">mobilize support</span>, and <span style="color:#FC8309">execute on a fix</span>.
  `,
  Promo: {
    Title: 'Everything you’ll need to keep your software infrastructure running',
    Body: `
      Unlock the full potential of your business with Netflare - the all-in-one platform for Operational Excellence. 
      Whether you're a small startup or a large enterprise, Netflare gives you the tools you need to grow and scale, 
      including monitoring, pager & ticketing all in one place. 
      Experience the convenience and efficiency of having all your operational needs met with a single solution - Netflare.
    `,
  },
  OurService: 'Our services',
  Ticket: {
    Title: 'Ticketing',
    Promotion: 'Your workflows made easy',
    Body: `
      Centralized issue tracking solution for efficient task assignment, prioritization, and progress monitoring. 
    `,
  },
  Pager: {
    Title: 'Pager',
    Promotion: `Reach the right people`,
    Body: `
      Stay informed and connected to what's important, even during an emergency, in order to swiftly address any problems that arise.
    `,
  },
  Runbook: {
    Title: 'Runbook',
    Promotion: `Resolve issues one step at a time`,
    Body: `
      Build a library of best practices to tackle any issue, anytime, with your team's collective knowledge.
    `,
  },
  Pulse: {
    Title: 'Pulse',
    Promotion: `Keep your web service alive`,
    Body: `
      Maintain your web service in top shape with a web monitoring tool that alerts you of any issues.
    `,
  },
  Alarm: {
    Title: 'Alarm',
    Promotion: `Keep your systems running`,
    Body: `
     Create automated processes for detecting and addressing unexpected downtime, ensuring timely resolution of any issues.
    `,
  },
  Footer: {
    Title: 'Optimizing the reliability, protection and performance of your digital operation.',
    Promotion: `
      Netflare was founded by a team of engineers with extensive experience in incident management, 
      bringing the same tools and techniques used by big tech companies to ensure a stable and reliable online experience, 
      to startups and enterprises like yours.
    `,
    Link: 'Get Started',
  },
  LearnMore: 'Learn more',
};
