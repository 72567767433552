export default {
  Meta: {
    Title: 'Get started',
    Description: `
      Here at Netflare, we strive to keep your customers happy by ensuring your digital presence is stable,
      secure and efficient. When things go wrong (and they do), Netflare allows you to create a workflow to immediately
      raise the alarm, automatically mobilize support, and execute on a fix.
    `,
  },
  Info: {
    Title: 'Start your <span style="color:#FC8309">free tier</span> plan',
    Body: `  
      <ul>
        <li>
          <p>Automate incident management</p>
        </li>
        <li>
          <p>Reduce downtime</p>
        </li>
        <li>
          <p>Keep your customers happy</p>
        </li>
      </ul>
    `,
    Book: 'Want to speak to an advisor? <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/mmajeed_netflare">Book a demo.</a>',
  },
  Signup: {
    GetStarted: 'Get started',
    Register: 'Register now with <span style="color:#FC8309">no credit card required.</span> ',
    Form: {
      FirstName: 'First name',
      LastName: 'Last name',
      Email: 'Work email',
      CompanyName: 'Company name',
      CompanyWebsite: 'Company website (e.g. www.example.com)',
      Workspace: 'Your Netflare workspace',
      Agreement: `I agree with the 
        <a href="/terms-and-conditions" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        and <a href="/privacy-policy" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
      Start: 'Start Your Free Plan',
      GetStarted: 'Get started',
      Next: 'Next',
      Skip: 'Skip',
      AlreadyUsed: 'That URL is unavailable. Please choose another one.',
      Limit: 'Use only letters and numbers for your workspace name, and keep it between 3 and 15 characters long.',
      HaveAccount: 'Already have an account? ',
      Login: 'Log in',
    },
  },
  SuccesfullyOnboarded: {
    Welcome: 'Welcome to Netflare!',
    Instruction: 'You will receive an email from us soon to kick-start your free plan.',
    Back: 'Back to home',
    Question: 'Have any questions? <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/mmajeed_netflare">Book a demo.</a>',
  },
};
