import React from 'react';

import {Link, AppBar, Grid, Typography, Box} from 'views/components';
import {Translate} from 'views/module';
import {NetflareLogo} from 'views/icons';
import {useLocation} from '@reach/router';


const APP_BAR_BACKGROUND = {
  '/': 'transparent',
  '/get-started/': {
    md: 'linear-gradient(90deg, #515151 0%, #111010 50.01%, #FFFFFF 50%)',
    xs: 'linear-gradient(90deg, #515151 0%, #111010 100%)',
  },
  '/login/': {
    md: 'linear-gradient(90deg, #515151 0%, #111010 50.01%, #FFFFFF 50%)',
    xs: 'linear-gradient(90deg, #515151 0%, #111010 100%)',
  },
  '/get-started-success/': {
    md: '#080808',
    xs: '#080808',
  },
  'DEFAULT': 'color.white',
};
const HIDE_AUTH = {
  '/get-started/': true,
  '/login/': true,
};
const TEXT_COLOR = {
  '/': 'color.black',
  '/get-started/': 'color.white',
  '/get-started-success/': 'color.white',
  '/login/': 'color.white',
  'DEFAULT': 'color.black',
};

const Navbar = () => {
  const location = useLocation();

  return (
    <>
      <AppBar
        position="relative"
        color='inherit'
        elevation={0}
        sx={{
          zIndex: 1000,
          py: '8px',
          background: APP_BAR_BACKGROUND[location.pathname] || APP_BAR_BACKGROUND['DEFAULT'],
        }}
      >
        <Grid container mt={{md: 1}} alignItems='center' justifyContent='space-between' >
          <Grid item lg={3} xs={5} pl={{md: 5, xs: 1}}>
            <Box display='flex' justifyContent={{xs: 'start', xl: 'center', xxl: 'end'}} >
              <Link
                to='/'
                underline='none'
              >
                <Typography
                  variant='logo2'
                  sx={{
                    'margin': 'auto',
                    'color': TEXT_COLOR[location.pathname] || TEXT_COLOR['DEFAULT'],
                  }}
                >
                  <NetflareLogo
                    sx={{
                      width: {md: '36px', xs: '24px'},
                      height: {md: '36px', xs: '24px'},
                    }}
                  />
                  {'   '}
                  <Translate expression='navbar.Netflare' />
                </Typography>
              </Link>
            </Box>
          </Grid>
          {
            !HIDE_AUTH[location.pathname] &&
            <Grid item xl={3} md={4} xs={7} pr={{md: 3, xs: 1}}sx={{backgroundColor: 'transparent'}}>
              <Box display='flex' justifyContent={{xs: 'end', xl: 'center', xxl: 'start'}}sx={{backgroundColor: 'transparent'}} >
                <Link
                  to='/login/'
                  underline='none'
                >
                  <Typography
                    variant='h5'
                    sx={{
                      'color': TEXT_COLOR[location.pathname] || TEXT_COLOR['DEFAULT'],
                      'fontWeight': 400,
                    }}
                    mt={1} mb={1}
                  >
                    <Translate expression='navbar.Login' />
                  </Typography>
                </Link>
                <Box sx={{
                  margin: {md: '0px 24px', xs: '0px 0px 0px 8px'},
                  padding: {md: '0px 24px', xs: '0px 8px 0px 8px'},
                  backgroundColor: 'primary.main',
                  borderRadius: '100px',
                }}>
                  <Link
                    to='/get-started/'
                    underline='none'
                  >
                    <Typography
                      variant='h5'
                      sx={{
                        color: 'color.white',
                        fontWeight: 400,
                      }}
                      mt={1} mb={1}
                    >
                      <Translate expression='navbar.GetStarted' />
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
          }
        </Grid>
      </AppBar>
    </>
  );
};

export default Navbar;
