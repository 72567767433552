import generic from './generic';
import navbar from './navbar';
import home from './home';
import blog from './blog';

import ticket from './product/ticket';
import pager from './product/pager';
import runbook from './product/runbook';
import pulse from './product/pulse';
import alarm from './product/alarm';

import ticketSlack from './product/ticket_slack';

import onboarding from './enrollment/onboarding';
import login from './enrollment/login';
import pricing from './enrollment/pricing';

import privacyPolicy from './policy/privacy_policy';
import termCondition from './policy/term_condition';

export default {
  generic,
  navbar,
  home,
  blog,

  ticket,
  pager,
  runbook,
  pulse,
  alarm,

  ticketSlack,

  onboarding,
  login,

  pricing,

  privacyPolicy,
  termCondition,
};
