import React from 'react';
import PropTypes from 'prop-types';

import {TextField as MUTextField} from '@mui/material';

const TextField = (props) => {
  const [isInvalid, setIsInvalid] = React.useState(false);

  const value = props.value || '';
  const onChange = props.onChange;
  const variant = props.variant;

  const validation = props.validation;

  const onUpdate = (e) => {
    onChange(e.target.value);
    if (validation && validation.regex && validation.update) {
      const re = new RegExp(validation.regex);
      const isValid = re.test(e.target.value);
      validation.update(isValid);
      setIsInvalid(!isValid);
    }
  };

  const validate = (e) => {
    if (validation && validation.regex && validation.update) {
      const re = new RegExp(validation.regex);
      const isValid = re.test(value);
      validation.update(isValid);
      setIsInvalid(!isValid);
    }
  };

  const inputProps = {...props};
  delete inputProps['validation'];

  return (
    <MUTextField
      {...inputProps}
      value={value}
      onChange={onUpdate}
      variant={variant || 'outlined'}
      onBlur={validate}
      error={isInvalid}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.object,
};

export default TextField;
