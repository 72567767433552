export default {
  Product: {
    Ticketing: 'Ticketing',
    Pager: 'Pager',
    Runbook: 'Runbook',
    Pulse: 'Pulse',
    Alarm: 'Alarm',
    Slack: 'Slack',
  },
  Discover: 'Discover our products',
  DiscoverHow: 'Discover how our products can improve your operational efficiency.',
  DiscoverProduct: {
    Ticketing: {
      Title: 'Ticketing',
      Body: 'Effectively distribute tasks',
    },
    Pager: {
      Title: 'Pager',
      Body: 'Stay in the loop at all times',
    },
    Runbook: {
      Title: 'Runbook',
      Body: 'Resolve issues step by step',
    },
    Alarm: {
      Title: 'Alarm',
      Body: 'Automatically detect issues',
    },
    Pulse: {
      Title: 'Pulse',
      Body: 'Monitor your web host',
    },
    Action: {
      Previous: '< Previous',
      Next: 'Next >',
    },
  },
  Signup: {
    Form: {
      Email: 'Enter your company email',
      Agreement: `By signing up, you agree with the  
        <a href="/terms-and-conditions" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Terms of Service</a>
        and <a href="/privacy-policy" style="color:#FC8309" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.`,
      GetStarted: 'Get started',
    },
  },
};
