/* eslint-disable max-len */
import React from 'react';
// import PropTypes from 'prop-types';

const PlayStore = () => {
  return (
    <a
      href="https://apps.apple.com/us/app/netflare/id1578976547?itsct=apps_box_badge&amp;itscg=30200"
      target="_blank" rel="noopener noreferrer"
    >
      <img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1635120000&h=e7752366997a597aee35e8aa1c0111b7"
        alt="Download on the App Store"
        style={{
          margin: '4%',
          width: '88%',
          maxWidth: '229px',
          maxHeight: '77px',
        }}
      />
    </a>
  );
};

PlayStore.propTypes = {
};
export default PlayStore;
