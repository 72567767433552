export default {
  Netflare: 'Netflare',
  Login: 'Login',
  GetStarted: 'Get Started',
  Product: {
    Products: 'Products',
    Ticketing: 'Ticketing',
    Pager: 'Pager',
    Runbook: 'Runbook',
    Pulse: 'Pulse',
    Alarm: 'Alarm',
  },
  Resource: {
    Resource: 'Resource',
    Pricing: 'Pricing',
    Blog: 'Blog',
  },
  AboutUs: {
    AboutUs: 'About us',
    Who: 'Who we are',
    Work: 'Work with us',
  },
  Final: {
    Copyright: `Copyright © ${new Date().getFullYear()} Netflare. All rights reserved.`,
    Policy: 'Privacy Policy',
    Term: 'Website Terms of Use',
  },
};
