/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

const Seo = ({title, description, lang, meta}) => {
  const {site} = useStaticQuery(
      graphql`
       query {
         site {
           siteMetadata {
             title
             description
           }
         }
       }
     `,
  );

  let metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  metaDescription = metaDescription.replace(/(\r\n|\n|\r)/gm, '').trim();

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={title}
      titleTemplate={defaultTitle ? ` ${defaultTitle} | %s` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
