// import React from 'react';
// import PropTypes from 'prop-types';

const LoadingScreen = () => {
  /*
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {
        loading &&
        <div style={{marginBottom: '5vh', minHeight: '100vh'}}>
        </div>
      }
    </>
  );
  */
  return null;
};

LoadingScreen.propTypes = {
};

export default LoadingScreen;

