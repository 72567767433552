import {useMediaQuery, useTheme} from '@mui/material';

const screenSizeBigger = (size) => {
  return useMediaQuery(useTheme().breakpoints.up(size), {noSsr: true});
};

const screenSizeSmaller = (size) => {
  return useMediaQuery(useTheme().breakpoints.down(size), {noSsr: true});
};

export {screenSizeBigger, screenSizeSmaller};
