import React from 'react';

import {FullSection} from 'views/components';
import {Divider, Typography, Link, Stack, Box} from 'views/components';
import {LinkedIn, Twitter} from 'views/icons';
import {TicketLogo, PagerLogo, RunbookLogo, PulseLogo, AlarmLogo} from 'views/icons';
import {Translate} from 'views/module';

const Footer = () => {
  return (
    <FullSection
      background={'color.white'}
      style={{
        my: {md: '80px', xs: '32px'},
        mx: {md: '80px', xs: '16px'},
      }}
    >
      <Stack direction={{lg: 'row', xs: 'column'}} spacing={{md: 2, xs: 4}}>
        <Box sx={{mr: {md: '160px', xs: '16px'}}}>
          <Stack>
            <Typography
              variant='h4'
              sx={{
                mb: '8px',
              }}
            >
              <Translate expression='navbar.Product.Products' />
            </Typography>
            <Box>
              <Stack direction={{md: 'row', xs: 'column'}} justifyContent={'space-between'} spacing={{md: 8, xs: 1}}>
                <Box>
                  <Link to={'/product/ticketing/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <TicketLogo sx={{mr: '8px'}}/>
                      <Translate expression='navbar.Product.Ticketing' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/product/pager/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <PagerLogo sx={{mr: '8px'}}/>
                      <Translate expression='navbar.Product.Pager' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/product/runbook/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <RunbookLogo sx={{mr: '8px'}}/>
                      <Translate expression='navbar.Product.Runbook' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/product/pulse/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <PulseLogo sx={{mr: '8px'}}/>
                      <Translate expression='navbar.Product.Pulse' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/product/alarm/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <AlarmLogo />
                      <Translate expression='navbar.Product.Alarm' />
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Typography
              variant='h4'
              sx={{
                mb: '8px',
              }}
            >
              <Translate expression='navbar.Resource.Resource' />
            </Typography>
            <Box>
              <Stack direction={{md: 'row', xs: 'column'}} justifyContent={'flex-start'} spacing={{md: 8, xs: 1}}>
                <Box>
                  <Link to={'/pricing/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <Translate expression='navbar.Resource.Pricing' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/blog/'} underline="none">
                    <Typography
                      variant='h5'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <Translate expression='navbar.Resource.Blog' />
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Divider sx={{my: {md: '60px', xs: '16px'}}}/>
      <Stack direction={{md: 'row', xs: 'column'}} justifyContent="space-between" spacing={{md: 4, xs: 2}}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box>
          <Stack>
            <Box>
              <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                <Box>
                  <Link to={'/privacy-policy/'} underline="none">
                    <Typography
                      variant='footer'
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <Translate expression='navbar.Final.Policy' />
                    </Typography>
                  </Link>
                </Box>
                <Box>
                  <Link to={'/terms-and-conditions/'} underline="none">
                    <Typography
                      variant='footer'
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'grey.700',
                      }}
                    >
                      <Translate expression='navbar.Final.Term' />
                    </Typography>
                  </Link>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Box>
              <Typography
                variant='footer'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'grey.700',
                }}
              >
                <a href={'https://linkedin.com/company/netflare-dev'} style={{color: 'color.darkGrey'}}>
                  <LinkedIn
                    sx={{width: '25px', height: '25px', paddingRight: '8px'}}
                    color='action'
                  />
                </a>
                <a href={'https://twitter.com/Netflare1'} style={{color: 'color.darkGrey'}}>
                  <Twitter
                    sx={{width: '25px', height: '20px', paddingRight: '8px'}}
                    color='action'
                  />
                </a>
                <Translate expression='navbar.Final.Copyright' />
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </FullSection>
  );
};

export default Footer;
