import React from 'react';
import PropTypes from 'prop-types';

const Video = (props) => {
  const [isShown, setIsShown] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const src = props.src;
  const style = props.style;

  const videoToPlay = (
    <video autoPlay loop muted playsInline
      style={{
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: '100%',
        height: 'auto',
        WebkitFilter: 'brightness(100%)',
        ...style,
      }}
    >
      <source src={src} type="video/mp4" />
    </video>
  );

  return (
    isShown ? videoToPlay : null
  );
};

Video.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
};

export default Video;
