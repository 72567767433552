import React from 'react';
import PropTypes from 'prop-types';

import MButton from '@mui/material/Button';

function Button(props) {
  return (
    <MButton
      {...props}
      variant={props.variant || 'contained'}
      fullWidth={props.fullWidth}
      color={props.color || 'primary'}
      disabled={props.disabled}
      sx={props.sx}
    >
      {props.label || props.children}
    </MButton>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

export {
  Button,
};
