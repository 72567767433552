export default {
  Meta: {
    Title: 'Ticketing | IT Support | Customer Service',
    Description: `
      Give your team a world-class ticketing and issue tracking system to help you deal with issues and incidents in your business.
      From the moment the incident is raised to its resolution, our ticketing system will be there to pick up the grunt work 
      so you can focus on the issue and be engaged with the customer.
    `,
  },
  Body: `
    World-class <span style="color:#2746AC">ticketing</span> and <span style="color:#2746AC">issue tracking system</span>
    to help you deal with issues and incidents
  `,
  SubBody: `
    From the moment the incident is <span style="color:#FC8309">raised</span> to its <span style="color:#FC8309">resolution</span>, 
    our ticketing system will be there so you can be focused on the issue and engaged with the customer.
  `,
  Centeralized: {
    Title: 'Centralized issue tracking system',
    1: {
      Title: 'Give better support, get happier customers',
      Body: `Capture your customers’ issues in a centralized system and keep track of them until resolution.`,
    },
    2: {
      Title: 'Save time and money',
      Body: `Time is money, and downtime is money lost for your business. Let us automate issue tracking so that you can be focused on problem solving.`,
    },
    3: {
      Title: 'Access from anywhere',
      Body: `
        Netflare provides the ability to access your issues  quickly, easily and as often as you would like,
        on a multitude of  platforms from your computer, mobile app or Slack to keep you connected and up-to-date.
      `,
    },
  },
  Triaging: {
    Title: 'Automated triaging',
    1: {
      Title: 'Triage based on urgency',
      Body: `
        Set priorities on your issues to create the appropriate sense of urgency, and Netflare will help you triage your 
        list based on the problem that needs the most attention.
      `,
    },
    2: {
      Title: 'Stay informed and on top of your issue',
      Body: `
        Setup workflows to automatically page your teams as tickets are created or updated, 
        to get the right people on the issue as soon as it occurs.
      `,
    },
    3: {
      Title: 'Automated ticket creation',
      Body: `
        Integrates with Netflare Alarm to monitor your service infrastructure to automatically generate tickets
        and alert your teams before your customers feel the pain.
      `,
    },
  },
  Resolve: {
    Title: 'Collaborate on resolving the issue',
    1: {
      Title: 'Consolidate your communication',
      Body: `
        Consolidate all your communications in a single place, collaborate with your co-workers on resolving the issue,
        and keep stakeholders up-to-date on progress.
      `,
    },
    2: {
      Title: 'Keep stakeholders informed',
      Body: `
        Netflare provides stakeholders real time updates on the latest comments and resolutions on tickets that matter to them.
      `,
    },
    3: {
      Title: 'Stay up-to-date',
      Body: `
        Subscribe to tickets that you care about and get notified through our paging system on the progress of the ticket.
        Bookmark important tickets to easily find them again.
      `,
    },
  },
  Action: {
    Previous: 'ALARM',
    Next: 'PAGER',
  },
};
