import React from 'react';
// import PropTypes from 'prop-types';

const PlayStore = () => {
  return (
    <a
      href='https://play.google.com/store/apps/details?id=com.opex'
      target="_blank" rel="noopener noreferrer"
    >
      <img
        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
        alt='Get it on Google Play'
        style={{
          width: '100%',
          height: 'auto',
          maxWidth: '260px',
          maxHeight: '101px',
        }}
      />
    </a>
  );
};

PlayStore.propTypes = {
};
export default PlayStore;
