import React from 'react';
import PropTypes from 'prop-types';

import {SvgIcon} from '@mui/material';

import BlackLogo from './black.svg';
import ColorFullLogo from './color_full.svg';
import ColorLogo from './color.svg';
import GreyLogo from './grey.svg';
import WhiteLogo from './white.svg';

const logo = {
  'BLACK': BlackLogo,
  'COLOR_FULL': ColorFullLogo,
  'COLOR': ColorLogo,
  'GREY': GreyLogo,
  'WHITE': WhiteLogo,
};

const Logo = (props) => {
  const propsSx = props.sx || {};
  const variant = props.variant;

  const Logo = logo[variant] || logo['COLOR'];

  return (
    <SvgIcon
      sx={{
        textAlign: 'center',
        verticalAlign: 'middle',
        ...propsSx,
      }}
      viewBox="0 0 24 24"
    >
      <Logo />
    </SvgIcon>
  );
};
Logo.propTypes = {
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['BLACK', 'COLOR_FULL', 'COLOR', 'GREY', 'WHITE']),
};

export default Logo;
