import React from 'react';
import PropTypes from 'prop-types';

import {Box, Grid} from 'views/components';

const FullSection = (props) => {
  const backgroundColor = props.background|| 'background.main';
  const backgroundImage = props.backgroundImage || {};
  const parentStyle = props.style || props.sx || {};

  const children = props.children;

  const style = {
    maxWidth: '100vw',
    overflowX: 'auto',

    backgroundColor,
    ...backgroundImage,

    ...parentStyle,
  };

  return (
    <Box sx={style} >
      <Grid container sx={{
        margin: 'auto',
        maxWidth: 'xl',
      }}>
        <Grid item
          xs={12}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

FullSection.propTypes = {
  children: PropTypes.node,
  background: PropTypes.string,
  backgroundImage: PropTypes.object,
  style: PropTypes.object,
  sx: PropTypes.object,
};

export default FullSection;
