import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Box, Typography} from 'views/components';
import {Accordion, AccordionSummary, AccordionDetails} from 'views/components';
import {screenSizeSmaller} from 'views/components';

const Image = (props) => {
  return (
    <>
      <Box sx={{
        position: 'relative',
        margin: 'auto',
      }}>
        {props.image}
      </Box>
    </>
  );
};
Image.propTypes = {
  image: PropTypes.any,
};


const Title = ({children}) => {
  return (
    <Typography
      variant='h4'
      sx={{
        'fontWeight': '700',
      }} m={1}>
      {children}
    </Typography>
  );
};
Title.propTypes = {
  children: PropTypes.any,
};

const Body = ({children}) => {
  return (
    <Typography variant='h5'>
      <span dangerouslySetInnerHTML={{__html: children}} />
    </Typography>
  );
};
Body.propTypes = {
  children: PropTypes.any,
};

const ScrollAccordion = ({text, title, expanded, setExpanded}) => {
  const slides = text.map((e) => {
    return {
      title: <Title>{e.title} </Title>,
      body: <Body>{e.body}</Body>,
    };
  });

  return (
    <>
      <Typography
        variant='h2'
        sx={{
          'textAlign': {md: 'left', xs: 'center'},
        }} m={1}>
        {title}
      </Typography>
      {
        slides.map((e, index) => {
          const isOpen = expanded === index + 1;
          return (
            <Accordion
              key={index}
              expanded={isOpen}
              onChange={() => setExpanded(index + 1)}
              sx={{
                'backgroundColor': 'inherit',
                '&:before': {display: 'none'},
                '.MuiAccordionSummary-content': {
                  margin: {md: '8px 0px', xs: '4px 0px'},
                },
                '.MuiAccordionSummary-root': {
                  px: '4px',
                },
                '.MuiAccordionDetails-root': {
                  padding: {md: '8px 16px 16px', xs: '4px 8px 8px'},
                },
              }}
              elevation={0}
            >
              <AccordionSummary sx={{color: isOpen ? 'primary.main' : 'color.lightGrey'}} expandIcon={<span />} >
                {e.title}
              </AccordionSummary>
              <AccordionDetails >
                {e.body}
              </AccordionDetails>
            </Accordion>
          );
        })
      }
    </>
  );
};
ScrollAccordion.propTypes = {
  text: PropTypes.any,
  title: PropTypes.any,
  expanded: PropTypes.any,
  setExpanded: PropTypes.any,
};

const MemoScrollAccordian = React.memo(ScrollAccordion);
const MemoImage = React.memo(Image);

const PictureAndScroll = (props) => {
  const [expanded, setExpanded] = React.useState(1);
  const [manuallySelected, setManuallySelected] = React.useState(false);

  const smallSized = screenSizeSmaller('lg');
  const outterBox = React.useRef();

  let reverse = props.reverse;
  if (smallSized) {
    reverse = true;
  }
  const backgroundColor = props.backgroundColor;
  const sx = props.sx;


  const handleScroll = () => {
    if (!manuallySelected) {
      const outterBoxLoc = outterBox?.current?.getBoundingClientRect();
      if (outterBoxLoc) {
        const outterTop = outterBoxLoc.top;
        const outterBottom = outterBoxLoc.bottom;
        const windowsHeight = (window.innerHeight || document.documentElement.clientHeight);
        const offset = (outterTop * -1);

        if (outterTop <= offset && outterBottom > windowsHeight && offset > 0) {
          const value = Math.min(Math.max(Math.ceil(((outterTop * -2) / (outterBoxLoc.height * 0.7)) * props.text.length), 1), props.text.length);
          setExpanded(value);
        }
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, [handleScroll]);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.requestAnimationFrame(handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  React.useLayoutEffect(() => {
    window.requestAnimationFrame(handleScroll);
  }, [handleScroll]);


  const changeExpanded = (newValue) => {
    outterBox.current.style['min-height'] = '0px';
    outterBox.current.scrollIntoView();
    setManuallySelected(true);
    setExpanded(newValue);
  };

  const image = <MemoImage image={props.image}/>;
  const text = <MemoScrollAccordian text={props.text} title={props.title} expanded={expanded} setExpanded={changeExpanded} />;

  const first = reverse ? text : image;
  const second = reverse ? image : text;

  const rowHeight = props.rowHeight || 50;
  const minHeight = `${Math.max(props.text.length * rowHeight, 180)}vh`;

  return (
    <Box sx={{
      backgroundColor,
      mt: '5vh',
      ...sx,
    }}>
      <Box sx={{
        margin: 'auto',
        maxWidth: 'xl',
      }}>
        <Box
          sx={{
            py: {md: '100px', xs: '0px'},
            minHeight,
          }}
          ref={outterBox}
        >
          <Box sx={{
            position: 'sticky',
            top: {xs: '0px'},
            minHeight: '100vh',
          }}>
            <Grid container
              direction={{lg: 'row', xs: 'column'}}
              alignItems='center'
              justifyContent='center'
              spacing={{lg: 0, xs: 2}}
              sx={{
                maxWidth: 'xl',
                minHeight: '95vh',
              }}>
              <Grid item lg={6} xs={12} >
                {first}
              </Grid>
              <Grid item lg={6} xs={12} >
                {second}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
PictureAndScroll.propTypes = {
  image: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
  reverse: PropTypes.any,
  backgroundColor: PropTypes.any,
  rowHeight: PropTypes.any,
  sx: PropTypes.any,
};

export default React.memo(PictureAndScroll);
