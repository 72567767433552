import React from 'react';
import PropTypes from 'prop-types';

import Navbar from './nav/Navbar';
import Footer from './nav/Footer';
import LoadingScreen from './nav/LoadingScreen';
import {Container} from 'views/components';

const Layout = ({children}) => {
  return (
    <>
      <LoadingScreen />
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          backgroundColor: 'color.white',
          maxWidth: '100vw',
        }}
      >
        <Navbar />
        {children}
        <Footer />
      </Container >
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;

