import React from 'react';
import PropTypes from 'prop-types';

import {theme} from '../../../styles/theme';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';

const Theme = ({children}) => {
  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
