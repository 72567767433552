import React from 'react';
import PropTypes from 'prop-types';

import Accordion from '@mui/material/Accordion';
import AccordionSummaryMUI from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {ExpandMore as ExpandMoreIcon} from 'views/icons';

const AccordionSummary = (props) => {
  return (
    <AccordionSummaryMUI
      className={props.className}
      expandIcon={<ExpandMoreIcon />}
      sx={props.sx}
    >
      {props.children}
    </AccordionSummaryMUI>
  );
};
AccordionSummary.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
  sx: PropTypes.object,
};


export {
  Accordion,
  AccordionSummary,
  AccordionDetails,
};
