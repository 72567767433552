import React from 'react';

import Layout from 'views/module/Layout';
import {Theme} from 'views/components';

import 'styles/global.css';

// Wraps every page in a component
const wrapPageElement = ({children}) => {
  return (
    <Theme>
      <Layout>
        {children}
      </Layout>
    </Theme>
  );
};

export default wrapPageElement;
