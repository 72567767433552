export default {
  Meta: {
    Title: 'Login',
    Description: `
      Here at Netflare, we strive to keep your customers happy by ensuring your digital presence is stable,
      secure and efficient. When things go wrong (and they do), Netflare allows you to create a workflow to immediately
      raise the alarm, automatically mobilize support, and execute on a fix.
    `,
  },
  Info: {
    Title: 'Designed to keep your system running.',
    Book: 'Need assistance? <a target="_blank" rel="noopener noreferrer" href="https://calendly.com/mmajeed_netflare">Book a demo.</a>',
  },
  Signup: {
    Welcome: 'Welcome back.',
    Signin: 'Sign In to Your Workspace',
    Form: {
      Workspace: 'Workspace name',
      Login: 'Login',
      HaveAccount: 'Need an account? ',
      GetStarted: 'Get started',
    },
  },
};
