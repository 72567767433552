import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'gatsby';
import MuiLink from '@mui/material/Link';

const CustomLink = (props) => {
  return (
    <MuiLink
      to={props.to}
      sx={props.sx}
      onClick={props.onClick}
      underline={props.underline}
      component={Link} /* Use router link with styling from material ui link */
    >
      {props.labelText || props.children}
    </MuiLink>
  );
};

CustomLink.propTypes = {
  labelText: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  to: PropTypes.string,
  underline: PropTypes.string,
  onClick: PropTypes.func,
};
export default CustomLink;
