import React from 'react';
import PropTypes from 'prop-types';

import MButton from '@mui/material/Button';
import {Link} from 'gatsby';

function ButtonLink(props) {
  return (
    <MButton
      {...props}
      variant={props.variant || 'contained'}
      color={props.color || 'primary'}
      component={Link}
    >
      {props.label || props.children}
    </MButton>
  );
}

ButtonLink.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
};

export {
  ButtonLink,
};
