export default {
  Meta: {
    Title: 'Pager | Dispatch | Issue',
    Description: `
      When issues occur, panic and chaos ensue. Netflare Pager allows you to keep things under control by quickly deploying your support team
      the moment an issue arises. With our fully automated dispatching system, you can deal with problems efficiently and effectively.
    `,
  },
  Body: `
    Set up a highly effective communication center to <span style="color:#2746AC">dispatch</span> your team where there needed the most
  `,
  SubBody: `
    Keep things under control with our Pager system by quickly <span style="color:#FC8309">deploying your support team</span> the moment an issue arises.
    With our fully automated dispatching system, you can deal with problems <span style="color:#FC8309">efficiently</span> 
    and <span style="color:#FC8309">effectively</span>.
  `,
  Notification: {
    Title: 'Notification',
    1: {
      Title: 'Get notified the way you like it',
      Body: `
        Through Netflare Pager multi-channel support, you can get notified where it’s most convenient for you.
        Netflare Pager will keep you informed  via Slack, our iOS & Android apps, email, SMS and even voice call.
      `,
    },
    2: {
      Title: 'Never miss a notification',
      Body: `
        Setup your own personal workflows for how we should route pages to you. Get notified on multiple channels at once,
        and set up personal reminders in case we didn’t get a hold of you the first time.
      `,
    },
    3: {
      Title: 'Improve your work/life balance',
      Body: `
        While all issues are important, some can wait until the next business day. 
        Our paging system allows you to select which notifications will only go out during business hours,
        to respect your employees’ time off and give them a better work/life balance.
      `,
    },
  },
  Oncall: {
    Title: 'Oncall schedule',
    1: {
      Title: 'Setup your first line of defense',
      Body: `
        Deal with issues effectively by having designated staff that are ready to jump on any problem at short notice.
        Use our oncall schedule to set up a rotation within your team to automatically cycle through the responsibility and ensure 
        you always have a first line of defense.
      `,
    },
    2: {
      Title: 'Quickly deploy your first responders',
      Body: `
        Page the oncall schedule directly, and leave it to us to route the notification to the person currently scheduled to be oncall.
      `,
    },
    3: {
      Title: 'Hassle free scheduling',
      Body: `
        No matter how you like to set up your schedule,
        our tool is flexible enough to handle it. We can help you set up everything,
        from a simple weekly rotation to the most complex schedule you can think of. 
        We also make it easy for you to figure out when you’re on call and identify your next shift.
      `,
    },
  },
  Escalation: {
    Title: 'Dispatch Policy',
    1: {
      Title: 'Mobilize multiple teams or individuals',
      Body: `
        Automate your dispatching through our system by telling us how to properly route the notification to your first responders.
      `,
    },
    2: {
      Title: 'Escalate if responders don’t arrive in time',
      Body: `
        When your first responders fail to acknowledge the page within the appropriate time,
        then we can escalate to the next level to make sure that the problem is being dealt with.
      `,
    },
  },
  Action: {
    Previous: 'TICKETING',
    Next: 'RUNBOOK',
  },
};
