import React from 'react';
import PropTypes from 'prop-types';

import en from 'lang/en';

const deepRead = (data, expression) => {
  const exp = expression.split('.');

  let retVal;
  do {
    retVal = (retVal || data)[exp.shift()];
  } while (retVal && exp.length);

  return retVal;
};

const Translate = ({expression}) => {
  const translatedString = translate(expression);

  return (
    <span
      dangerouslySetInnerHTML={{__html: translatedString}}
    />
  );
};
Translate.propTypes = {
  expression: PropTypes.string,
};

const translate = (expression) => {
  const result = deepRead(en, expression);
  if (result === null || result === false || result === undefined) {
    return 'Missing translation';
  }
  return result;
};

export {
  Translate,
  translate,
};
